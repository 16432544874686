import React from 'react';
import ScenarioCard from '@components/atoms/new-proposal/financial-card';
import LegendContainer from '@components/atoms/new-proposal/legend-container';
import { IScenariosRiskComparison, RiskTypeEnum, riskTypeIndexer } from '@contexts/product-one/types';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';

import './styles.css';

interface IScenariosRiskComparisonProps {
  scenariosRiskComparisonData: IScenariosRiskComparison;
  risk: keyof typeof RiskTypeEnum;
  isWhiteLabelProposal: boolean;
}

function ScenariosRiskComparison({
  scenariosRiskComparisonData,
  risk,
  isWhiteLabelProposal,
}: IScenariosRiskComparisonProps) {
  const sectionStyles = 'w-full flex flex-col  h-screen p-8 sm:gap-20';
  const currentRisk = riskTypeIndexer[risk].masculineWord;
  return (
    <SlideContainer isVisible={isWhiteLabelProposal}>
      <section className={sectionStyles}>
        <LegendContainer legend="Simulador de viabilidade" />

        <div className="w-full flex flex-col sm:gap-10 items-start sm:items-start sm:px-13 xl:items-start relative">
          <div className="w-full flex flex-col sm:gap-4 ">
            <span className="text-heading-xsmall xl:text-heading-large text-[#222222]">
              <p className="text-justify w-64 sm:w-full">{`O resultado anterior é para um produto ${currentRisk}`}</p>
              <b className="text-heading-small text-primary-70 xl:text-heading-xlarge mt-2 sm:text-nowrap ">
                {`Contudo, a economia pode ser ${risk == 'BOLD' ? 'menor' : 'maior'} em um produto ${
                  risk == 'BOLD' ? riskTypeIndexer.CONSERVATIVE.masculineWord : riskTypeIndexer.BOLD.masculineWord
                }.`}
              </b>
            </span>
          </div>
          <div className="w-full h-full flex flex-col-reverse gap-y-8 gap-x-0 xl:flex-row-reverse xl:gap-y-0 xl:gap-x-5 2xl:gap-x-20 items-center xl:justify-between ">
            {Object.entries(scenariosRiskComparisonData).map(([key, value], index) => (
              <React.Fragment key={key}>
                <ScenarioCard
                  index={index}
                  key={key}
                  title={key == 'bold' ? '<p>Produto <b>arrojado</b></p>' : '<p>Produto de <b>baixo risco</b></p>'}
                  totalSavingPercentage={value.totalSavingPercentage}
                  totalSaving={value.totalSaving}
                  aclPrices={value.aclPrices}
                  color={key == 'bold' ? 'primary-90' : 'primary-70'}
                  totalSavingByMonth={value.totalSavingByMonth}
                />
                {index === 0 && <div className="w-full h-1 xl:w-1 xl:h-60 xl:rotate-180 bg-neutral-20" />}
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>
    </SlideContainer>
  );
}

export default ScenariosRiskComparison;
