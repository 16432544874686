import useWhiteLabel from '@hooks/use-white-label';
import { formatNumber } from '@utils/numeral';
import { validationDatePricesExpirationDate } from '@utils/datetime';

import { IScenariosRiskComparison, RiskTypeEnum, riskTypeIndexer } from '@contexts/product-one/types';

import AnnualDetailCard from '@components/atoms/new-proposal/icon-card/annual-detail';
import CardHeaderProposal from '@components/atoms/new-proposal/icon-card/header';
import EconomyCardComponent from '@components/molecules/new-proposal/feasibility-analysis/feasibility-grafic/economy';
import LegendContainer from '@components/atoms/new-proposal/legend-container';
import NewSimplifiedProposalEconomyChart from '@components/molecules/new-proposal/feasibility-analysis/feasibility-grafic/chart';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';

import { ChartData, IAnnualDetail } from './helpers';
import './styles.css';

interface IEconomyData {
  totalSaving: string;
  totalSavingPercentage: string;
  totalSavingByMonth: string;
  migrationDate: string;
  migrationCost: string;
  managementCost: string;
  totalInitialExpenses: string;
}
interface IFeasibilitySimulationProps {
  leadName?: string;
  chartData: ChartData;
  annualDetail: IAnnualDetail[];
  gridCols: number;
  lastCard: number;
  economyData: IEconomyData;
  pricesExpirationDate?: string;
  risk?: keyof typeof RiskTypeEnum;
  scenariosRiskComparisonData?: IScenariosRiskComparison | undefined;
  targetYear?: number;
}

function FeasibilitySimulation({
  leadName,
  economyData,
  chartData,
  annualDetail,
  gridCols,
  lastCard,
  pricesExpirationDate,
  risk,
  scenariosRiskComparisonData,
}: IFeasibilitySimulationProps) {
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();
  const isManagementPath = window.location.pathname.includes('clarke-gestao');

  return (
    <SlideContainer className="sm:h-full xl:min-h-screen xl:h-fit min-h-fit mr-5 sm:mr-0 ">
      <div className="flex flex-col sm:py-6 xl:px-5 2xl:px-10 xl:pb-0">
        <LegendContainer
          isWhiteLabelProposal={isWhiteLabelProposal}
          className="legend-container-styles"
          legend="Simulação de Viabilidade"
        />
        {isManagementPath ? (
          <span
            style={{
              color: isWhiteLabelProposal ? whiteLabelSupplier?.colors?.main : '#00DF7C',
            }}
            className="text-styles-container-span"
          >
            {`Em nossa simulação ${
              scenariosRiskComparisonData ? `${riskTypeIndexer[risk ?? 'CONSERVATIVE'].feminineWord},` : ''
            } `}
            <strong className="text-styles-paragraph">
              a economia será
              <b className="text-heading-small xl:text-display-medium xl:leading-[3rem]">
                {` ${economyData.totalSavingPercentage}`}
              </b>
            </strong>
          </span>
        ) : (
          <span
            style={{
              color: isWhiteLabelProposal ? whiteLabelSupplier?.colors?.main : '#00DF7C',
            }}
            className="text-styles-container-span"
          >
            <strong className="text-styles-paragraph">
              Sua economia será{' '}
              <b className="text-heading-small xl:text-display-medium xl:leading-[3rem]">
                {economyData.totalSavingPercentage}
              </b>
            </strong>
          </span>
        )}

        <div className="grid-styles">
          <div className="chart-container-styles">
            <div className="chart-card-styles">
              <CardHeaderProposal
                className="mb-6"
                title="Economia projetada por ano"
                icon="CurrencyDollarIcon"
                imageSvg=""
              />
              {chartData && <NewSimplifiedProposalEconomyChart {...chartData} />}
            </div>

            <EconomyCardComponent
              title="Economia"
              icon="BanknotesIcon"
              imageSvg=""
              totalValue={economyData.totalSaving}
              percentValue={economyData.totalSavingPercentage}
              monthlyEconomy={economyData.totalSavingByMonth}
              migrationDate={economyData.migrationDate}
              migrationCost={economyData.migrationCost}
              managementCost={economyData.managementCost}
              totalInitialExpenses={economyData.totalInitialExpenses}
              source="Incentivada 50%"
            />
          </div>

          <div className="detail-card-container-styles h-fit p-5">
            <CardHeaderProposal className="mb-5" title="Detalhamento anual" icon="CurrencyDollarIcon" imageSvg="" />
            <div className={`annual-detail-container-styles xl:grid xl:grid-cols-${gridCols} col-span-${gridCols}`}>
              {annualDetail.map((item, index) => (
                <div key={`${item.year}-${item.supply}`} className={`${index !== lastCard && 'border-r-2'}`}>
                  <AnnualDetailCard
                    year={item.year}
                    supply={formatNumber(item.supply as number, '$ 00,0.00')}
                    freeMarket={formatNumber(item.freeMarket, '$ 00,0.00')}
                    economy={formatNumber(item.economy, '$ 00,0.00')}
                    captiveMarket={formatNumber(item.captiveMarket, '$ 00,0.00')}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <span
          style={{
            color: isWhiteLabelProposal ? whiteLabelSupplier?.colors?.main : '#00DF7C',
          }}
          className="text-primary-70 justify-end w-full flex py-5 px-2 sm:px-5"
        >
          Proposta válida para {leadName} até {validationDatePricesExpirationDate(pricesExpirationDate)}
        </span>
      </div>
    </SlideContainer>
  );
}

export default FeasibilitySimulation;
