interface TableHeaderProps {
  headers: string[];
}

export function TableHeader({ headers }: TableHeaderProps) {
  return (
    <thead>
      <tr className="bg-gray-100">
        {headers?.map((header) => (
          <th key={header} className="py-2 px-4 border border-gray-200 text-left">
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
}
