import { AxiosPromise } from 'axios';
import { FeedbackNotificationProps } from '@clarke-energia/foton';
import { ICompetitiveAnalysisData } from '@components/molecules/general/simulation/competitor-comparison/parser';
import { IProductTwoSimulationStatus } from './product-two/types';
import { RiskTypeEnum } from './product-one/types';

export enum SimulatorTypeEnum {
  CLARKE_MANAGEMENT,
  CLARKE_RETAIL,
  CONTRACTED_DEMAND,
  WHITE_LABEL,
}

type ISimulatorLabel = 'Clarke Gestão' | 'Clarke Varejo' | 'Demanda Contratada' | 'White-Label';

export type IDeleteProposalsByIds = (leadId: string, proposalId: Array<string>) => AxiosPromise<IResponseBase>;

export const simulatorLabelIndexer: Record<keyof typeof SimulatorTypeEnum, ISimulatorLabel> = {
  CLARKE_MANAGEMENT: 'Clarke Gestão',
  CLARKE_RETAIL: 'Clarke Varejo',
  CONTRACTED_DEMAND: 'Demanda Contratada',
  WHITE_LABEL: 'White-Label',
};

export const getSimulatorLabel = (simulatorType: keyof typeof SimulatorTypeEnum) => {
  return simulatorLabelIndexer[simulatorType];
};

export enum ContractGeneratorTypeEnum {
  PRODUCT_ONE = 'Clarke Gestão',
  PRODUCT_TWO = 'Clarke Varejo',
}

/*
 *
 * SIMULATIONS BASES TYPES
 *
 * Theses types are used by ALL
 * simulators and represents the
 * base types.
 *
 */
export interface IProposalBase<
  IInputs extends ISimulationPayloadBase,
  ICalculatorMetadata extends ISimulationBase<ISimulationPayloadBase> | Record<string, any>,
> extends IProposalLinkedUsers {
  id: string;
  lead: IProposalLead;
  createdAt: string;
  deletedAt?: string;
  inputs: IInputs;
  calculatorMetadata: ICalculatorMetadata;
}

export interface ICustomFeeAuxiliarFields {
  uniqueMargin?: number;
  isMarginUnique?: boolean;
}

type CustomFeeType = 'ABSOLUTE' | 'PERCENTAGE' | undefined;

export interface ICustomFee {
  type: CustomFeeType;
  values?: Record<string, any>;
  auxiliarFields?: ICustomFeeAuxiliarFields;
}
export interface ISimulationPayloadBase extends IProposalLinkedUsers {
  aclType: keyof typeof AclTypeEnum;
  calculationType: string;
  lead?: string;
  hasManualDiscount?: boolean;
  hasManualPrice?: boolean;
  manualPricesOrDiscountsStartYear?: number;
  manualAclPrice?: Record<string, Record<string, number>>;
  manualDiscountInputs?: Record<string, Record<string, number>>;
  totalMigrationCost?: number;
  proposalValidity?: string;
  targetYears?: Array<number>;
  targetYear?: number;
  units?: Array<ISimulationUnitPayloadBase>;
  productType?: keyof typeof ProductTypeEnum;
  description?: string;
  competitorsList?: Array<ISimulationCompetitorPayloadBase>;
  useIRecCertification?: boolean;
  exclusiveTrader?: boolean;
  whiteLabelSupplierId?: string;
  risk?: keyof typeof RiskTypeEnum;
  customFee?: ICustomFee;
  serviceProposalPricesDiscountPercentage?: number;
}

export interface IProposalsAggregatedByLeadBase<T> {
  lead: IProposalLead;
  proposals: Array<T>;
}

export interface LatestProposalMetadata {
  proposalId: string;
  description?: string;
  seller?: IProposalUser;
  author: IProposalUser;
  status?: keyof typeof IProductTwoSimulationStatus;
  commercialDealerComission?: number;
  createdAt: string;
}
export interface ISimulatorIndexedMetadata {
  latestProposalMetadata?: LatestProposalMetadata;
  proposalsNumber?: number;
}
export interface ILeadSimulatorsIndexedMetadata {
  productOne?: ISimulatorIndexedMetadata;
  productTwo?: ISimulatorIndexedMetadata;
}

export interface IProposalLead {
  id: string;
  name: string;
  createdAt: string;
  deletedAt?: string | null;
  simulatorsIndexedMetadata?: ILeadSimulatorsIndexedMetadata;
}

export interface IProposalUser {
  id: string;
  name: string;
  pictureUrl: string;
}
export interface IProposalLinkedUsers {
  author?: IProposalUser;
  seller?: IProposalUser | null;
}

export type IProposalCommercialStage = 'approved' | 'waitingForApproval';

export interface ILeadProposalsHubHistoryBase {
  leadId: string;
  selectedProposalId: string | undefined;
  setSelectedProposalId: React.Dispatch<React.SetStateAction<string | undefined>>;
  feedbackNotificationsContent?: FeedbackNotificationProps;
}

export interface ILeadProposalsHubBase extends ILeadProposalsHubHistoryBase {
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IProposalPreviewBodyBase extends ILeadProposalsHubHistoryBase {
  isMainContent?: boolean;
  onClickDeleteProposal: (leadId: string, selectedProposalId: string) => Promise<void>;
}

export interface ISimulationBase<ISimulationInputs> {
  aclType: keyof typeof AclTypeEnum;
  lead: string;
  inputs: ISimulationInputs;
  pricesExpirationDate?: string;
}
export interface IContractUnitPayloadBase {
  comsumptionUnitName: string;
  unitManagementPrice: string;
  unitAdress: string;
}
export interface IContractPayloadBase {
  units?: Array<IContractUnitPayloadBase>;
}

export interface IContractBase {
  units?: Array<IContractUnitPayloadBase>;
}

export enum ProductTypeEnum {
  ACL_RETAILER = 'Varejista',
  ACL_WHOLESALER = 'Atacadista',
}

export enum GuaranteeTypeEnum {
  NO_GUARANTEE = 'Sem garantia',
  CONTRACTUAL_INSURANCE = 'Seguro Contratual',
  BANK_GUARANTEE = 'Fiança Bancária',
  SECURITY_DEPOSIT = 'Depósito Caução',
}

export enum ManagementTerminationFineEnum {
  THREE_MONTHS_NOTICE = '3 meses de aviso prévio',
  SIX_MONTHS_NOTICE = '6 meses de aviso prévio',
  THIRTY_PERCENT_OF_THE_CONTRACT_RESIDUAL = '30% do residual do contrato',
  FIFTY_PERCENT_OF_THE_CONTRACT_RESIDUAL = '50% do residual do contrato',
  HAS_A_FINE = 'Possui multa',
  NO_FINE = 'Não possui multa',
}

export enum ManagerTypeEnum {
  INDEPENDENT_MANAGER = 'Gestora independente',
  SALES_MANAGER = 'Gestora comercializadora',
}

export enum ChargesCoverageTypeEnum {
  FULL_COVERAGE_FOR_ENERGY_CONTRACT = 'Cobertura total pelo contrato de energia',
  FULL_PASS_THROUGH_OF_COSTS_TO_THE_CUSTOMER = 'Repasse integral dos custos ao cliente',
}

export enum ChargesCoverageTypeForManualAclPricesEnum {
  true = 'Cobertura total pelo contrato de energia',
  false = 'Repasse integral dos custos ao cliente',
}

export enum AclTypeEnum {
  FIXED_PRICE = 'Preço Fixo',
  GUARANTEED_DISCOUNT = 'Desconto Garantido',
}

export interface ISimulationUnitPayloadBase {
  consumptionOffPeak?: Record<number, number | null>;
  consumptionPeak?: Record<number, number | null>;
  consumptionLowVoltage?: Record<number, number | null>;
  managementPrice: number;
  aclContractedDemandOffPeak?: number;
  aclContractedDemandPeak?: number;
  acrContractedDemandOffPeak?: number;
  acrContractedDemandPeak?: number;
  distributedGenerationMonthlyCost?: number;
  energyDistributionCompany?: number;
  icmsType?: string;
  initialExpenses?: number;
  injectedEnergyOffPeak?: Record<string, number>;
  injectedEnergyPeak?: Record<string, number>;
  migrationDate?: string;
  nickname?: string;
  powerGeneratorCost?: number;
  targetVolumeGenerated?: number;
  tariff?: number;
  operationHoursRange?: IOperationHoursRangeSet;
  companyType?: number;
}

export interface ISimulationCompetitorPayloadBase {
  productType?: keyof typeof ProductTypeEnum;
  guaranteeType?: keyof typeof GuaranteeTypeEnum;
  managerType?: keyof typeof ManagerTypeEnum;
  chargesCoverageType?: keyof typeof ChargesCoverageTypeEnum;
  upperFlexibility?: number;
  lowerFlexibility?: number;
  monthlyManagementPricePerUnit?: number;
  managementTerminationFine?: keyof typeof ManagementTerminationFineEnum;
  saving?: number;
  aclPrices?: Record<string, number>;
}

export interface IOperationHoursRangeSet {
  businessDays: IOperationHoursRange;
  saturday: IOperationHoursRange | null;
  sunday: IOperationHoursRange | null;
}

export interface IOperationHoursRange {
  openingTime: string;
  closingTime: string;
}

interface ISimulationResultBaseUnit {
  carbonDioxideEmissionInTonsAvoidedByYear?: Record<string, number>;
  energyFeeMonthlyByYear?: Record<string, number>;
  grossMerchandiseVolumeByYear?: Record<string, number>;
  takeRateByYear?: Record<string, number>;
  totalCarbonDioxideEmissionInTonsAvoided?: number;
  totalGrossMerchandiseVolume?: number;
  totalLifetimeValue?: number;
  totalManagementPrice: number;
  totalSaving: number;
  savingByMonth: number;
  totalTakeRate?: number;
}
export interface IDiscountFreeServiceProposalPrices {
  discountPercentage: number;
  totalManagementPrice: number;
  // TODO after two weks remove optional condition
  totalMonthlyManagementPrice?: number;
  totalMigrationCost: number;
}
export interface ISimulationResultBase extends ISimulationResultBaseUnit {
  calculationType?: string;
  competitiveAnalysis?: Array<ICompetitiveAnalysisData>;
  discountFreeServiceProposalPrices: IDiscountFreeServiceProposalPrices;
  feasibility?: boolean;
  useIRecCertification: boolean;
  iRecCertificationCost?: number;
  iRecCertificationCostMonthly?: number; // TODO after two weks remove optional condition
  totalMigrationCost?: number;
  totalAverageConsumption?: number;
  totalCurrentSpentByYear: Record<string, number>;
  totalGuaranteedDiscountSpentByYear?: Record<string, number>;
  totalInitialExpenses?: number;
  totalManagementPriceByYear?: Record<string, number>;
  totalManagementPriceByYearPercentage: Record<string, number>;
  totalSavingByYear: Record<string, number>;
  totalSavingByMonth: number;
  totalSavingByYearPercentage: Record<string, number>;
  totalSavingPercentage: number;
  totalMonthlyManagementCost?: number;
  totalTargetSpentByYear: Record<string, number>;
  commercialDealerComission: ICommercialDealerComission;
  unitsReports: Array<ISimulationReportBase>;
  vpl: number;
}

export interface ISimulationReportBase {
  averageConsumption: number;
  carbonDioxideEmissionInTonsAvoidedByYear?: Record<string, number>;
  currentSpentByYear: Record<string, number>;
  energyFeeMonthlyByYear?: Record<string, number>;
  grossMerchandiseVolumeByYear?: Record<string, number>;
  initialExpenses?: number;
  manualAclPrice: Record<string, Record<string, string | number>>;
  managementPriceByYear?: Record<string, number>;
  totalMigrationCost?: number;
  nickname: string;
  priceListWithInflation: Record<string, Record<string, string | number>>;
  priceList?: Record<string, Record<string, string | number>>;
  savingByYear: Record<string, number>;
  savingByYearInPercentage: Record<string, number>;
  savingIn24Monhts: number;
  takeRateByYear?: Record<string, number>;
  targetSpentByYear: Record<string, number>;
  totalCarbonDioxideEmissionInTonsAvoided?: number;
  totalGrossMerchandiseVolume?: number;
  totalLifetimeValue?: number;
  totalManagementPrice: number;
  totalMonths: number;
  totalSaving: number;
  savingByMonth: number;
  totalSavingInPercentage: number;
  totalTakeRate?: number;
}

export interface IUpdateSellerPayload {
  proposalId: string;
  seller: IProposalUser;
  leadId: string;
}

/*
 *
 * TARIFF FLAG SCENARIO
 *
 * Represent the simulation data
 * based on specific energy
 * tariff flags scenarios.
 *
 * Used by Feasibility Analysis
 * and Comparison Bill simulators.
 *
 */

export interface ISimulationTariffFlagScenariosBase {
  green: ISimulationTariffFlagScenarioBase;
  redP1: ISimulationTariffFlagScenarioBase;
  redP2: ISimulationTariffFlagScenarioBase;
  yellow: ISimulationTariffFlagScenarioBase;
  default: ISimulationTariffFlagScenarioBase;
}

export interface ISimulationTariffFlagScenarioBase extends ISimulationReportBase {
  averageSavingByMonth: number;
  sampleMonthSimulation: ISampleMonthSimulation;
  totalSavingWithInitialExpenses: number;
  totalSavingInPercentageWithInitialExpenses: number;
}

/*
 *
 * SAMPLE MONTH SIMULATION
 *
 * Represent the entire simulation
 * created using a sample month
 * (in the middle of the contract).
 *
 * This type is shared by Feasibility
 * Analysis and Comparison Bill simulators.
 *
 */

export interface ISampleMonthSimulation {
  currentMarket: ISampleMonthSimulationCurrentMarket;
  targetMarket: ISampleMonthSimulationTargetMarket;
  report: ISampleMonthSimulationReport;
}

interface ISampleMonthSimulationReport {
  month: number;
  monthLabel: string;
  totalSavingInPercentageWithTaxes: number;
  totalSavingWithTaxes: number;
  year: number;
}

interface ISampleMonthSimulationMarketBase {
  consumptionOffPeak: number;
  consumptionPeak: number;
  demandOffPeak: number;
  demandPeak: number;
  distributionTariffOffPeak: number;
  distributionTariffPeak: number;
  monthGeneratorFactor: number;
  monthYear: string;
  tariff: ISimulationTariff;
  totalWithoutTaxes: number;
  totalWithTaxes: number;
}

interface ISampleMonthSimulationCurrentMarket extends ISampleMonthSimulationMarketBase {
  electricityTariffOffPeak: number;
  electricityTariffPeak: number;
  generatorCost: number;
  injectedEnergyOffPeak: number;
  tariffFlag: number;
  totalPisCofins: number;
  totalWithIcms: number;
}

interface ISampleMonthSimulationTargetMarket extends ISampleMonthSimulationMarketBase {
  contributionCcee: number;
  demandOffPeakDiscount: number;
  demandPeakDiscount: number;
  distributionIcms: number;
  distributionPisCofins: number;
  distributionTariffPeakDiscount: number;
  electricityIcms: number;
  electricityTariff: number;
  electricityWithLosses: number;
  icms: Record<string, any>;
  managementPrice: number;
  pisCofinsHighlight: number;
  priceListWithInflation: Array<Record<string, any>>;
  securityAndModulationEnergyCharges: number;
  stimulatedDemandOffPeak: number;
  stimulatedDemandPeak: number;
  stimulatedDistributionTariffPeak: number;
}

interface ISimulationTariff {
  demandOffPeak: number;
  demandPeak: number | null;
  distributionTariffOffPeak?: number;
  distributionTariffPeak?: number;
  electricityTariffOffPeak?: number;
  electricityTariffPeak?: number;
  energyDistributionCompany: Record<string, any>;
  id: number;
  stimulatedDemandOffPeak?: number;
  stimulatedDemandPeak?: number;
  stimulatedDistributionTariffPeak?: number;
  tariffAdjustmentDate: string;
  tariffType: number;
}

/*
 *
 * SIMULATION EXTENDED RESULT
 *
 * Speciement of common type to
 * unify differents simulators results.
 *
 */

export interface ISimulationExtendedResultBase extends ISimulationResultBase {
  aclPrices?: Array<Record<string, string>>;
  leadName?: string;
  currentSpent: number;
  currentSpentByYear: Record<string, number>;
  currentSpentByYearList: Array<number>;
  duration?: number;
  initialExpenses?: number;
  proposalValidity?: string;
  retailTraderId: string;
  saving: number;
  savingByYear: Record<string, number>;
  savingByYearPercentageList: Array<number>;
  savingPercentage: number;
  simulationResultYearsList: Array<string>;
  targetSpent: number;
  targetSpentByYear: Record<string, number>;
  targetSpentByYearList: Array<number>;
  totalSaving: number;
}

/*
 *
 * SIMULATION PREMISES
 *
 * The entire set of premises
 * used in the simulation, including
 * tariffs, prices and taxes.
 *
 */

export interface ISimulationPremises {
  general: ISimulationPremisesGeneral;
  icms?: ISimulationPremisesIcms;
  edcInfo?: ISimulationPremisesEdcInfo;
  tariffsList?: Array<ISimulationPremisesTariff>;
  aclPrices?: Array<ISimulationPremisesAclPrices>;
}

interface ISimulationPremisesGeneral {
  pis: number;
  cofins: number;
  basicNetworkLosses: number;
  securityAndReservationCharges: number;
  modulation: number;
  clarkeManagement: number;
  cceeContribution: number;
  accessionCcee: number;
  adequationSmf: number;
  aclDemandDiscount: number;
  electricityPisCofins: number;
  tariffFlag?: JSON;
}

interface ISimulationPremisesIcms {
  fixedValue: number;
  id: number;
  percentageValue: number;
  referenceDate: string;
  taxType: string;
}

interface ISimulationPremisesEdcInfoFPGDRelation {
  percentage: number;
}

interface ISimulationPremisesEdcInfo {
  fixedPriceGuaranteedDiscountRelation: ISimulationPremisesEdcInfoFPGDRelation;
  id: number;
  name: string;
  removesIcms: boolean;
}

interface ISimulationPremisesAclPrices {
  id: number;
  year: string;
  fixedPrice: number;
}

export interface ISimulationPremisesTariff {
  demandOffPeak: number;
  demandPeak: number;
  distributionTariffOffPeak: number;
  distributionTariffPeak: number;
  electricityTariffOffPeak: number;
  electricityTariffPeak: number;
  energyDistributionCompany: ISimulationPremisesTariffEdcInfo;
  id: number;
  tariff: number;
  tariffAdjustmentDate: string;
}

interface ISimulationPremisesTariffEdcInfo {
  id: number;
  name: string;
}

/*
 *
 * LOW TENSION TYPES
 *
 * These types are from low tension simulation in Clarke Varejo.
 *
 */
export enum LowTensionTypeEnum {
  OPTING = 'BT Optante',
  STANDARD = 'BT Padrão',
}

/*
 *
 * COMMONS TYPES
 *
 * These types are only abstractions.
 *
 */

export interface IResponseBase<T = undefined> {
  code: string;
  message?: string;
  success: boolean;
  data: T;
}

export interface IPaginatedResponseBase<T> {
  limit: number;
  page: number;
  total: number;
  data: Array<T>;
}

export interface IProposalEconomyProjectionTableData {
  item: JSX.Element;
  investment: string;
  total: string;
}

export interface IGeneralTitleAndValuePair {
  title: string;
  value?: string | number;
}

export interface ICommercialDealerComission {
  initialAmount: number;
  inCashAmount: number;
  inInstallmentsAmount: number;
  totalAmount: number;
  numberOfInstallments: number;
}
