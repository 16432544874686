import * as uuid from 'uuid';
import { twMerge } from 'tailwind-merge';
import { parseValue } from './helper';

interface TableRowProps {
  row: (string | number | JSX.Element)[];
  tagStyle: string;
  rowIndex: number;
}

export function TableRow({ row, tagStyle, rowIndex }: TableRowProps) {
  return (
    <tr
      id={`price-list-composition-data-tbody-${row[0]}-${rowIndex}`}
      key={`price-list-composition-data-tbody-${uuid.v4()}`}
      className={twMerge('hover:bg-primary-10', (rowIndex + 1) % 2 === 0 ? 'bg-gray-50' : 'bg-white')}
    >
      {row?.map((cell, index) => (
        <td
          id={`price-list-composition-data-td-body-${row[0]}${cell}`}
          key={`price-list-composition-data-td-body-${uuid.v4()}`}
          className="py-2 pl-4 pr-10 border border-gray-200 text-left text-nowrap hover:bg-primary-10"
        >
          <div className={index === 1 ? tagStyle : ''}>
            <span className="flex items-center gap-5">{parseValue(cell)}</span>
          </div>
        </td>
      ))}
    </tr>
  );
}
