import { useEffect, useState } from 'react';
import { useAuth } from '@src/auth-wrapper';
import { CLARKE_RETAIL_PATH } from '@routers/constants';
import { calculateObjectValuesAverage } from '@utils/common';
import { IProposalPreviewBodyBase } from '@contexts/types';
import { IProductTwoProposal } from '@contexts/product-two/types';
import { calculateProposalManagementFeeMonthly } from '@contexts/calculator/utils';
import useProductTwo from '@hooks/use-product-two';
import useManagementContract from '@hooks/use-management-contract';

import ProposalPreviewDescriptionAndUsers from '@components/atoms/general/lead-proposals-hub/proposal-preview-description-and-users';
import LeadProposalsHubBodyWrapper from '@components/molecules/simulations-list/lead-proposals-hub-body-wrapper';
import CommercialDealerComissionTrading from '@components/atoms/general/lead-proposals-hub/commercial-dealer-comission-trading';

import {
  ProductTwoProposalKeyMetricsPanel,
  ProductTwoProposalKeyMetricsPanelForCommercialDealer,
  ProductTwoTraderSection,
} from './helper';

interface IProductTwoProposalPreviewBody extends IProposalPreviewBodyBase {
  leadProposal: IProductTwoProposal;
}

const ProductTwoProposalPreviewBody = ({
  leadProposal,
  leadId,
  selectedProposalId,
  setSelectedProposalId,
  onClickDeleteProposal,
  feedbackNotificationsContent,
  isMainContent = false,
}: IProductTwoProposalPreviewBody): JSX.Element => {
  const { user } = useAuth();
  const { updateSellerOfProductTwoProposal, updateProductTwoProposalCommercialDealerComission } = useProductTwo();
  const { getManagementContracts } = useManagementContract();

  const [showComissionNegotiation, setShowComissionNegotiation] = useState<boolean>(false);
  const [isContractSigned, setIsContractsSigned] = useState(false);

  const bestRetailTraderResult = leadProposal?.calculatorMetadata?.bestRetailTraderResult?.calculatorMetadata;

  const retailTradersResults = leadProposal?.calculatorMetadata?.retailTradersResult;
  const contractDuration = leadProposal?.calculatorMetadata?.bestRetailTraderResult?.inputs?.targetYear;

  const isProposalComplete = leadProposal?.calculatorMetadata?.bestRetailTraderResult !== undefined;
  const commercialDealerComission =
    leadProposal?.calculatorMetadata?.bestRetailTraderResult?.calculatorMetadata?.commercialDealerComission;

  useEffect(() => {
    if (user) {
      const conditionsToEnableComissionNegotiation = user.isCommercialDealer && isMainContent && !isContractSigned;
      setShowComissionNegotiation(conditionsToEnableComissionNegotiation);
    }
  }, [user, isMainContent, isContractSigned]);

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const contractData = await getManagementContracts('PRODUCT_TWO', 'SIGNED', leadProposal.id);
        if (contractData.data.success) {
          if (contractData.data.data.total > 0) {
            setIsContractsSigned(true);
          }
        }
      } catch (erro) {
        setIsContractsSigned(false);
      }
    };

    fetchContracts();
  }, [leadProposal.id]);

  return (
    <LeadProposalsHubBodyWrapper
      isProposalComplete={isProposalComplete}
      simulationPreviewFooterProps={{
        simulatorType: 'CLARKE_RETAIL',
        simulatorPath: CLARKE_RETAIL_PATH,
        simulationId: leadProposal?.id,
        leadName: leadProposal?.id,
        inputsValuesToCopy: leadProposal?.inputs,
        leadId: leadId,
        selectedProposalId: selectedProposalId,
        setSelectedProposalId: setSelectedProposalId,
        onClickDeleteProposal: onClickDeleteProposal,
        feedbackNotificationsContent: feedbackNotificationsContent,
      }}
    >
      <>
        <ProposalPreviewDescriptionAndUsers
          proposalId={leadProposal.calculatorMetadata?.bestRetailTraderResult?.id}
          description={leadProposal.inputs.description}
          author={leadProposal.author}
          seller={leadProposal.seller}
          leadId={leadId}
          updateProposalSeller={updateSellerOfProductTwoProposal}
        />
        {bestRetailTraderResult && user?.isCommercialDealer && (
          <ProductTwoProposalKeyMetricsPanelForCommercialDealer
            carbonDioxideEmissionInTonsAvoided={bestRetailTraderResult?.totalCarbonDioxideEmissionInTonsAvoided}
          />
        )}
        <ProductTwoTraderSection retailTradersResults={retailTradersResults} contractDuration={contractDuration} />
        {bestRetailTraderResult && !user?.isCommercialDealer && (
          <ProductTwoProposalKeyMetricsPanel
            energyFeeMonthly={calculateObjectValuesAverage(bestRetailTraderResult?.energyFeeMonthlyByYear)}
            carbonDioxideEmissionInTonsAvoided={bestRetailTraderResult?.totalCarbonDioxideEmissionInTonsAvoided}
            grossMerchandiseVolume={bestRetailTraderResult?.totalGrossMerchandiseVolume}
            lifetimeValue={bestRetailTraderResult?.totalLifetimeValue}
            managementFeeMonthly={calculateProposalManagementFeeMonthly(bestRetailTraderResult.unitsReports)}
            totalAverageConsumption={bestRetailTraderResult?.totalAverageConsumption}
            takeRate={bestRetailTraderResult?.totalTakeRate}
            isMainContent={isMainContent}
          />
        )}
        {showComissionNegotiation && (
          <CommercialDealerComissionTrading
            proposalId={leadProposal.id}
            commercialDealerComission={commercialDealerComission}
            updateProposalCommercialDealerComission={updateProductTwoProposalCommercialDealerComission}
          />
        )}
      </>
    </LeadProposalsHubBodyWrapper>
  );
};

export default ProductTwoProposalPreviewBody;
