import { HeroIcon } from '@clarke-energia/foton';

import numeral from 'numeral';
import { twMerge } from 'tailwind-merge';

import {
  ChargesCoverageTypeEnum,
  GuaranteeTypeEnum,
  ManagementTerminationFineEnum,
  ManagerTypeEnum,
  ProductTypeEnum,
} from '@contexts/types';

import { ICompetitiveAnalysisData, ICompetitveAnalysisItem, ICompetitveAnalysisItemAssessment } from './parser';

const renderIcon = (assessment: ICompetitveAnalysisItemAssessment) => {
  const commonClassNames = 'w-6 p-2 lg:w-7 rounded-lg';
  switch (assessment) {
    case 'POSITIVE':
      return <HeroIcon icon="CheckIcon" className={twMerge(commonClassNames, 'bg-primary-10')} />;
    case 'NEGATIVE':
      return <HeroIcon icon="NoSymbolIcon" className={twMerge(commonClassNames, 'bg-danger-10')} />;
    case 'REGULAR':
      return <HeroIcon icon="ExclamationTriangleIcon" className={twMerge(commonClassNames, 'bg-warning-10')} />;
  }
};

const valueAndIconCompetitorData = ({ value, assessment }: ICompetitveAnalysisItem) => {
  return (
    <div className="flex items-center w-36 md:w-full">
      <div>{renderIcon(assessment)}</div>
      <div>
        <div className={`h-fit py-3 md:py-5 pl-4 flex items-center`}>{value}</div>
      </div>
    </div>
  );
};

export const parseManagerTypeItem = (competitor: ICompetitiveAnalysisData): JSX.Element => {
  return valueAndIconCompetitorData({
    value: ManagerTypeEnum[competitor.managerType.value as keyof typeof ManagerTypeEnum],
    assessment: competitor.managerType.assessment,
  });
};

export const parseProductTypeItem = (competitor: ICompetitiveAnalysisData): JSX.Element => {
  return valueAndIconCompetitorData({
    value: ProductTypeEnum[competitor.productType.value as keyof typeof ProductTypeEnum],
    assessment: competitor?.productType.assessment,
  });
};

export const parseUpperAndLowerFlexibilityItem = (competitor: ICompetitiveAnalysisData): JSX.Element => {
  return valueAndIconCompetitorData({
    value: `+ ${numeral(competitor.upperFlexibility.value).format('0')}% / - ${numeral(
      competitor.lowerFlexibility.value,
    ).format('0')}%`,
    assessment: competitor?.upperFlexibility.assessment,
  });
};

export const parseGuaranteeTypeItem = (competitor: ICompetitiveAnalysisData): JSX.Element => {
  return valueAndIconCompetitorData({
    value: GuaranteeTypeEnum[competitor.guaranteeType.value as keyof typeof GuaranteeTypeEnum],
    assessment: competitor.guaranteeType.assessment,
  });
};

export const parseCoverCceeTaxesItem = (competitor: ICompetitiveAnalysisData): JSX.Element => {
  return valueAndIconCompetitorData({
    value: ChargesCoverageTypeEnum[competitor.chargesCoverageType.value as keyof typeof ChargesCoverageTypeEnum],
    assessment: competitor.chargesCoverageType.assessment,
  });
};

export const parseMonthlyManagementPricePerUnitItem = (competitor: ICompetitiveAnalysisData): JSX.Element => {
  // TODO remove conditional after two weeks of implementation
  const totalMonthlyManagementCost = competitor?.totalMonthlyManagementCost
    ? competitor?.totalMonthlyManagementCost.value
    : competitor.monthlyManagementPricePerUnit.value;
  return valueAndIconCompetitorData({
    value: numeral(totalMonthlyManagementCost).format('$ 0,0.00'),
    assessment: competitor.monthlyManagementPricePerUnit.assessment,
  });
};

export const parseManagementTerminationFineItem = (competitor: ICompetitiveAnalysisData): JSX.Element => {
  return valueAndIconCompetitorData({
    value:
      ManagementTerminationFineEnum[
        competitor.managementTerminationFine.value as keyof typeof ManagementTerminationFineEnum
      ],
    assessment: competitor.managementTerminationFine.assessment,
  });
};

export const parseSavingItem = (competitor: ICompetitiveAnalysisData): JSX.Element => {
  return valueAndIconCompetitorData({
    value: (
      <div>
        <div>{numeral(competitor.saving.value).format('$ 0,0.00')}</div>
        {competitor?.savingByMonth && <div>({numeral(competitor.savingByMonth.value).format('$ 0,0.00')} / mês)</div>}
      </div>
    ),
    assessment: competitor.saving.assessment,
  });
};

export const parseAclPricesItem = (competitor: ICompetitiveAnalysisData): JSX.Element => {
  return valueAndIconCompetitorData({
    value: (
      <div className="flex w-full justify-between gap-x-4 divide-x-1 divide-neutral-20 pr-5">
        {Object.keys(competitor.aclPrices).map((year) => {
          return (
            <div
              key={`competitive-analysis-acl-prices-for-${competitor.saving}-in-year-${year}`}
              className="flex flex-col w-full pl-3"
            >
              <p className="text-neutral-50 text-caption-small md:text-caption-medium">{year}</p>
              <p className="text-paragraph-small md:text-paragraph-medium ">
                {numeral(competitor.aclPrices[year]).format('0,0')}
              </p>
            </div>
          );
        })}
      </div>
    ),
  });
};
