import { twMerge } from 'tailwind-merge';
import useWhiteLabel from '@hooks/use-white-label';
import { formatNumber } from '@utils/numeral';
import CardSimpleProposal from './simple-proposal';

interface ICardProposalContainer {
  supply: number;
  economy: number;
  captiveMarket: number;
  freeMarket: number;
}

function CardProposalContainer({ supply, economy, captiveMarket, freeMarket }: ICardProposalContainer) {
  const isSimplifiedProposal = window.location.pathname.includes('proposta-simplificada');
  const { isWhiteLabelProposal } = useWhiteLabel();

  return (
    <div
      className={twMerge(
        'grid grid-flow-row grid-cols-2 col-span-1 xl:max-w-52 h-24 sm:min-h-fit justify-between whitespace-nowrap min-w-fit gap-4',
        !isSimplifiedProposal && 'xl:gap-x-10',
      )}
    >
      <CardSimpleProposal
        label={isSimplifiedProposal ? 'Preço (R$/MWh)' : 'Fornecimento (R$/MWh)'}
        defaultValue={formatNumber(supply, '$ 00,0.00')}
        labelStyle="text-paragraph-small"
        defaultValueClassName="font-bold text-paragraph-small"
      />
      <CardSimpleProposal
        label={'Economia'}
        defaultValue={formatNumber(economy, '$ 00,0.00')}
        labelStyle="text-paragraph-small"
        defaultValueClassName={twMerge(
          isWhiteLabelProposal ? 'text-paragraph-small text-white-label-main' : 'text-paragraph-small text-primary-70',
        )}
      />
      <CardSimpleProposal
        className="mt-[-1.25rem]"
        label={'Mercado Cativo'}
        defaultValue={formatNumber(captiveMarket, '$ 00,0.00')}
        labelStyle="text-paragraph-small"
        defaultValueClassName="font-bold text-paragraph-small"
      />
      <CardSimpleProposal
        className="mt-[-1.25rem]"
        label={'Mercado Livre'}
        defaultValue={formatNumber(freeMarket, '$ 00,0.00')}
        labelStyle="text-paragraph-small"
        defaultValueClassName="font-bold text-paragraph-small"
      />
    </div>
  );
}

export default CardProposalContainer;
