export const EnergyComparisonHeader = ({
  icon,
  title,
  rankingPosition,
}: {
  icon: string;
  title: string;
  rankingPosition?: number;
}) => (
  <div className="energy-comparison-header" data-cy={`${title.replace(' ', '-')}-header`}>
    <img src={icon} alt="Ícone de energia" />
    <span>
      <p className="energy-comparison-title">{title}</p>
      {rankingPosition === 1 && <p className="energy-comparison-subtitle">Melhor opção</p>}
    </span>
  </div>
);
