import { twMerge } from 'tailwind-merge';
import { useState } from 'react';
import { HeroIconSolidOrOutline } from '@components/atoms/form/hero-icon';

export function DropdownSection({
  title,
  children,
  className,
  iconColor,
  iconName,
  fixedOpen,
  titleBackgroundColor,
  titleBackgroundColorOnHover,
}: {
  title: string;
  children: React.ReactNode;
  className?: string;
  iconColor?: string;
  fixedOpen?: boolean;
  iconName?:
    | 'CalculatorIcon'
    | 'CashIcon'
    | 'ChartBarIcon'
    | 'CurrencyDollarIcon'
    | 'ExclamationCircleIcon'
    | 'LightningBoltIcon'
    | 'OfficeBuildingIcon'
    | 'PresentationChartBarIcon'
    | 'PresentationChartLineIcon'
    | 'TableIcon'
    | 'TrendingDownIcon'
    | 'TrendingUpIcon'
    | 'DatabaseIcon';
  titleBackgroundColor?: string;
  titleBackgroundColorOnHover?: string;
}) {
  const [isOpen, setIsOpen] = useState(true);
  const buttonStyle = twMerge(
    `${className} bg-${titleBackgroundColor ? titleBackgroundColor : 'brand-gray-10'} hover:bg-${
      titleBackgroundColorOnHover ? titleBackgroundColorOnHover : 'brand-gray-20'
    }`,
    'flex gap-4 w-full text-left py-2 px-4 rounded-t-lg focus:outline-none ',
  );
  return (
    <div className={twMerge(!className ? 'border-2 border-t-0  rounded-t-lg flex flex-col' : className)}>
      <button onClick={() => !fixedOpen && setIsOpen(!isOpen)} className={buttonStyle}>
        {iconName ? (
          <HeroIconSolidOrOutline
            useSolid={false}
            className={twMerge('w-6 h-6 ', iconColor || 'text-gray-500')}
            icon={iconName}
          />
        ) : (
          <HeroIconSolidOrOutline
            className="w-6 h-6 text-primary-70"
            useSolid={false}
            icon={isOpen ? 'FolderOpenIcon' : 'FolderIcon'}
          />
        )}
        {title}
      </button>
      {isOpen && <div className="p-6 overflow-x-auto flex flex-col gap-5">{children}</div>}
    </div>
  );
}
