import { getIconsList } from './helper';
import { EnergyComparisonItem } from './item';

export const EnergyComparisonBody = ({
  energyType,
  fill,
  colors,
}: {
  energyType: 'i50' | 'i100';
  fill: string;
  colors: string;
}) => (
  <div className="energy-comparison-body" data-cy={`body-${energyType}`}>
    {getIconsList(energyType).map((icon) => (
      <EnergyComparisonItem
        key={`icon-list-${energyType}-${icon.iconName}`}
        fill={fill}
        colors={colors}
        iconName={icon.iconName as 'wind' | 'dolar' | 'ligthBulb'}
        description={icon.description}
      />
    ))}
  </div>
);
