import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TARIFF } from '@utils/constants';
import useProductTwo from '@hooks/use-product-two';
import useProductOne from '@hooks/use-product-one';
import { ISimulationResultBase } from '@contexts/types';
import { IProductOneSimulationResult, IProductOneUnitReport } from '@contexts/product-one/types';
import ErrorUpdateTheProposal from '@pages/error-update-the-proposal';

import LegendContainer from '@components/atoms/new-proposal/legend-container';
import { GenerateTablesByUnit } from '@components/atoms/general/generateTablesByUnits';
import { GenerateTableGroupInfo } from '@components/atoms/general/generateTableGroupInfo';
import { DropdownSection } from '@components/atoms/general/dropdonw';
import { GenerateEnergyTypeComparisonDataAnalysis } from '@components/atoms/general/energyTypeComparison';

import { parseProposalToUnitsReportsRetail, parseProposalToUnitsReportsManagement } from './helper';
import { IUnitReport } from './types';

enum UnitTypes {
  BT = 'Baixa Tensão',
  MT = 'Média Tensão',
  ALL = 'Baixa e Média Tensão',
}

const UnitSectionByType = ({
  units,
  unitType,
  alternativeEnergyTypeUnits,
}: {
  units: IUnitReport[];
  unitType: string;
  alternativeEnergyTypeUnits: IUnitReport[];
}) => {
  if (units?.length === 0) return null;
  return (
    <div className="flex flex-col relative border-2 p-5 shadow-lg">
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        {unitType === 'BT' ? 'Unidades Baixa Tensão' : 'Unidades Média Tensão'}
      </h3>

      <div className="flex flex-col gap-5 px-5">
        <GenerateTablesByUnit units={units} alternativeEnergyTypeUnits={alternativeEnergyTypeUnits} />
      </div>
    </div>
  );
};
export function DetailedAnalysis() {
  const {
    getProductTwoProposalById,
    proposal: productTwoProposal,
    isValidProposalProductTwo,
    isLoadingProductTwo,
  } = useProductTwo();
  const {
    getProductOneProposalById,
    proposal: productOneProposal,
    isValidProposalProductOne,
    isLoadingProductOne,
  } = useProductOne();

  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const isRetailPath = window.location.pathname.includes('clarke-varejo');

  const proposalData: ISimulationResultBase = isRetailPath
    ? (productTwoProposal?.calculatorMetadata?.bestRetailTraderResult
        ?.calculatorMetadata as IProductOneSimulationResult)
    : (productOneProposal?.calculatorMetadata?.firstResult as IProductOneSimulationResult);

  const energyTypeComparison = isRetailPath
    ? productTwoProposal?.calculatorMetadata?.bestRetailTraderResult?.calculatorMetadata?.energyTypeComparison
    : productOneProposal?.calculatorMetadata?.firstResult?.energyTypeComparison;

  const unitsReport = isRetailPath
    ? parseProposalToUnitsReportsRetail(proposalData?.unitsReports)
    : parseProposalToUnitsReportsManagement(proposalData?.unitsReports as Array<IProductOneUnitReport>);

  const alternativeEnergyTypeUnitsReport = isRetailPath
    ? parseProposalToUnitsReportsRetail(energyTypeComparison?.alternativeEnergyTypeResult?.unitsReports)
    : parseProposalToUnitsReportsManagement(
        energyTypeComparison?.alternativeEnergyTypeResult?.unitsReports as Array<IProductOneUnitReport>,
      );

  const { id } = useParams();
  useEffect(() => {
    isRetailPath && getProductTwoProposalById(id);
    isManagementPath && getProductOneProposalById(id as string);
  }, [id]);
  const lowVoltageUnits = unitsReport?.filter(
    (unit) => unit?.premises?.tariff === TARIFF?.B3_STANDARD || unit?.premises?.tariff === TARIFF?.B3_OPTING,
  );
  const mediumVoltageUnits = unitsReport?.filter(
    (unit) => !(unit?.premises?.tariff === TARIFF?.B3_STANDARD || unit?.premises?.tariff === TARIFF?.B3_OPTING),
  );
  const alternativeEnergyTypeLowVoltageUnits = alternativeEnergyTypeUnitsReport?.filter(
    (unit) => unit?.premises?.tariff === TARIFF?.B3_STANDARD || unit?.premises?.tariff === TARIFF?.B3_OPTING,
  );
  const alternativeEnergyTypeMediumVoltageUnits = alternativeEnergyTypeUnitsReport?.filter(
    (unit) => !(unit?.premises?.tariff === TARIFF?.B3_STANDARD || unit?.premises?.tariff === TARIFF?.B3_OPTING),
  );
  const multipleUnits = lowVoltageUnits?.length > 0 && mediumVoltageUnits?.length > 0;
  const unitType = ['BT', 'MT', 'ALL'];
  const [selectedUnitType, setSelectedUnitType] = useState<UnitTypes>(UnitTypes.ALL);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedUnitType(UnitTypes[selectedValue as keyof typeof UnitTypes]);
  };
  const isValidProposal = isRetailPath ? isValidProposalProductTwo : isValidProposalProductOne;
  const isLoadingProposal = isRetailPath ? isLoadingProductTwo : isLoadingProductOne;
  const proposalCreatedAt = isRetailPath ? productTwoProposal.createdAt : productOneProposal?.createdAt;

  if (isValidProposal && !isLoadingProposal) {
    return (
      <div className="flex flex-col p-20">
        <LegendContainer className="mb-6" fontSizeInPX={40} legend="Análise Detalhada" />
        <div className={`grid grid-cols-1 gap-8 ${multipleUnits && 'mb-10'}`}>
          <DropdownSection title="Dados do Grupo" fixedOpen>
            <div className="flex flex-col relative">
              <div className="flex flex-col gap-5">
                <GenerateTableGroupInfo proposalData={proposalData} />
              </div>
            </div>
            {energyTypeComparison && (
              <div className="flex flex-col relative my-2 ">
                <div className="flex flex-col gap-5">
                  <GenerateEnergyTypeComparisonDataAnalysis proposalData={energyTypeComparison} />
                </div>
              </div>
            )}
          </DropdownSection>
          {multipleUnits && (
            <div className="mb-6">
              <label htmlFor="unit-select" className="block mb-2 text-sm font-medium text-gray-700">
                Exibir unidades por tipo
              </label>
              <select
                id="unit-select"
                onChange={handleSelectChange}
                className="bg-primary-60 rounded-full w-60 px-5 py-4 border "
              >
                {unitType?.map((type) => (
                  <option className="bg-primary-10 py-4 rounded-se-md rounded-tl-md" key={type} value={type}>
                    {UnitTypes[type as keyof typeof UnitTypes]}
                  </option>
                ))}
              </select>
            </div>
          )}
          <DropdownSection title="Dados das Unidades" fixedOpen>
            {selectedUnitType === UnitTypes.ALL && (
              <div className=" flex flex-col gap-5">
                <UnitSectionByType
                  unitType="BT"
                  units={lowVoltageUnits as IUnitReport[]}
                  alternativeEnergyTypeUnits={alternativeEnergyTypeLowVoltageUnits}
                />
                <UnitSectionByType
                  unitType="MT"
                  units={mediumVoltageUnits as IUnitReport[]}
                  alternativeEnergyTypeUnits={alternativeEnergyTypeMediumVoltageUnits}
                />
              </div>
            )}
            {selectedUnitType === UnitTypes.BT && (
              <div className="flex flex-col relative">
                <h3 className="text-lg font-semibold mb-4 flex items-center">Unidades Baixa Tensão</h3>
                <div className="flex flex-col gap-5">
                  <GenerateTablesByUnit
                    units={lowVoltageUnits}
                    alternativeEnergyTypeUnits={alternativeEnergyTypeLowVoltageUnits}
                  />
                </div>
              </div>
            )}
            {selectedUnitType === UnitTypes.MT && (
              <div className="flex flex-col relative">
                <h3 className="text-lg font-semibold mb-4 flex items-center">Unidades Média Tensão</h3>
                <div className="flex flex-col gap-5">
                  <GenerateTablesByUnit
                    units={mediumVoltageUnits}
                    alternativeEnergyTypeUnits={alternativeEnergyTypeMediumVoltageUnits}
                  />
                </div>
              </div>
            )}
          </DropdownSection>
        </div>
      </div>
    );
  } else if (!isValidProposal && proposalCreatedAt) {
    return <ErrorUpdateTheProposal />;
  }
}
