import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HOME_PATH } from '@routers/constants';
import { Layout, LayoutProps } from '@clarke-energia/foton';
import eventTracker from '@services/eventTracking';
import { getCalculatorName } from '@utils/common';
import { eventLabels } from '@services/eventTracking/eventsLabels';
import { useAuth } from '@src/auth-wrapper';
enum GeneratorTypeEnum {
  WHITE_LABEL = 'White-Label',
}
interface IWhiteLabelLayoutBase<T> extends LayoutProps {
  children: React.ReactNode;
  witheLabelType: keyof typeof GeneratorTypeEnum;
  witheLabelFlow?: 'WHITE_LABEL_GENERATOR_FORM' | 'WHITE_LABEL_UPDATE_FORM' | 'WHITE_LABEL_LIST';
}
const WitheLabelLayoutBase = <T,>({
  children,
  witheLabelType,
  witheLabelFlow,
  ...layoutProps
}: IWhiteLabelLayoutBase<T>) => {
  const navigate = useNavigate();
  const baseLinks = [
    {
      label: 'Home',
      url: `${HOME_PATH}`,
    },
    {
      label: GeneratorTypeEnum[witheLabelType],
      url: '#',
    },
  ];
  const { user } = useAuth();
  const navigationHistoryLinks: LayoutProps['navigationHistoryLinks'] = [...baseLinks];
  const handleButtonAddWhiteLabelSupplier = () => {
    navigate('./adicionar');
    eventTracker.trackEvent(eventLabels.BUTTON_ADD_WHITE_LABEL_SUPPLIER, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  };
  const headerButtons: LayoutProps['headerButtons'] =
    witheLabelFlow === 'WHITE_LABEL_LIST'
      ? [
          {
            kind: 'primary',
            label: 'Adicionar fornecedora',
            icon: 'PlusIcon',
            className: 'text-paragraph-medium',
            onClick: handleButtonAddWhiteLabelSupplier,
          },
        ]
      : [];
  return (
    <Layout
      {...layoutProps}
      navigationHistoryLinks={navigationHistoryLinks}
      darkerBackground
      headerButtons={headerButtons}
      className="h-full overflow-y-hidden pb-16"
    >
      <div className="col-span-full h-full p-2 md:p-5 overflow-y-auto">{children}</div>
    </Layout>
  );
};
export default WitheLabelLayoutBase;
