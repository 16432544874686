import { IUnitReport } from '@pages/detailed-analysis/types';
import { DropdownSection } from './dropdonw';

export const JsonViewer = ({ jsonData, className }: { jsonData: IUnitReport; className: string }) => {
  const jsonString = JSON.stringify(jsonData, null, 2);

  const jsonLines = jsonString.split('\n');

  return (
    <DropdownSection
      title="Visualização dos dados brutos"
      className="grid grid-cols-1 rounded-t-lg bg-white min-w-fit w-full"
    >
      <div className={className} style={{ fontFamily: 'monospace', whiteSpace: 'pre' }}>
        {jsonLines.map((line, index) => (
          <div key={index}>
            <span style={{ color: 'gray' }}>{index + 1}</span>: {line}
          </div>
        ))}
      </div>
    </DropdownSection>
  );
};
