import { AxiosPromise, AxiosResponse } from 'axios';

import {
  ISimulationPayloadBase,
  ISimulationReportBase,
  ISimulationResultBase,
  ISampleMonthSimulation,
  ISimulationExtendedResultBase,
  IGeneralTitleAndValuePair,
  IResponseBase,
  ISimulationBase,
  IProposalBase,
  IUpdateSellerPayload,
  IContractPayloadBase,
  ICommercialDealerComission,
} from '@contexts/types';
import { IPriceListComposition } from '@pages/detailed-analysis/types';

export interface IProductOneContext {
  proposal?: IProductOneProposal;
  isLoadingProductOne: boolean;
  isValidProposalProductOne: boolean;
  createProductOneSimulation: (simulationData: IProductOneSimulatorInputs) => AxiosPromise;
  createProductOneProposal(simulatorResult: AxiosResponse<IResponseBase<any>>): AxiosPromise;
  getProductOneProposalById: (proposalId: string) => Promise<IProductOneProposal | null>;
  getProductOneProposals: (
    leadId?: string,
    sellerId?: string,
  ) => AxiosPromise<IResponseBase<Array<IProductOneProposal>>>;
  deleteProductOneProposalsHandler: (leadId: string, proposalsIds: Array<string>) => AxiosPromise<IResponseBase>;
  deleteProductOneAllProposalsByLeadIdHandler: (leadId: string) => AxiosPromise<IResponseBase>;
  updateSellerOfProductOneProposal: (updateSellerData: IUpdateSellerPayload) => AxiosPromise<IResponseBase>;
  updateProductOneProposalCommercialDealerComission: (
    proposalId: string,
    commercialDealerComissionData: ICommercialDealerComission,
  ) => AxiosPromise;
}

export type IProductOneContractInputs = IContractPayloadBase;

export interface IProductOneSimulatorInputs extends ISimulationPayloadBase {
  manualAclPricesHasChargesCoverage?: boolean;
}

export type IProductOneProposal = IProposalBase<IProductOneSimulatorInputs, IProductOneSimulation>;

export interface IProductOneSimulation extends ISimulationBase<IProductOneSimulatorInputs> {
  firstResult: IProductOneSimulationResult;
  secondResult?: IProductOneSimulationResult;
  thirdResult?: IProductOneSimulationResult;
}

export enum RiskTypeEnum {
  CONSERVATIVE = 'Conservador',
  BOLD = 'Arrojado',
}

export const riskTypeIndexer: Record<keyof typeof RiskTypeEnum, any> = {
  CONSERVATIVE: {
    startsWithCapitalLetter: 'Conservador',
    feminineWord: 'conservadora',
    masculineWord: 'conservador',
  },
  BOLD: {
    startsWithCapitalLetter: 'Arrojado',
    feminineWord: 'arrojada',
    masculineWord: 'arrojado',
  },
};

export type IAclPrices = Record<string, number>;

export interface IScenarioData {
  totalSaving: number;
  totalSavingPercentage: number;
  totalSavingByMonth: number;
  aclPrices: IAclPrices;
}
export interface IScenariosRiskComparison {
  bold: IScenarioData;
  conservative: IScenarioData;
}

interface IEnergyTypeComparisonItem {
  totalSaving: number;
  totalSavingPercentage: number;
  rankingPosition: number;
}
export interface IProductOneEnergyTypeComparison {
  incentivized50: IEnergyTypeComparisonItem;
  incentivized100: IEnergyTypeComparisonItem;
  alternativeEnergyTypeResult: IProductOneSimulationResult;
  alternativeEnergyType: string;
}

export interface IProductOneSimulationResult extends ISimulationResultBase {
  targetYearGroup: number;
  unitsReports: Array<IProductOneUnitReport>;
  scenariosRiskComparison?: IScenariosRiskComparison;
  energyTypeComparison?: IProductOneEnergyTypeComparison;
}

export interface IProductOneSimulationExtendedResult extends ISimulationExtendedResultBase {
  unitsReports: Array<IProductOneUnitReport>;
  premises: Array<IGeneralTitleAndValuePair>;
}

export interface IProductOneUnitReport extends ISimulationReportBase {
  premises: IProductOneUnitReportPremises;
  sampleMonthSimulation?: ISampleMonthSimulation;
}

interface IProductOneUnitReportPremises {
  aclContractedDemandOffPeak: number;
  aclContractedDemandPeak?: number;
  acrContractedDemandOffPeak: number;
  acrContractedDemandPeak?: number;
  averageBillValue: number;
  consumptionOffPeak: Array<number> | Record<string, any>;
  consumptionPeak: Array<number> | Record<string, any>;
  distributedGenerationMonthlyCost: Record<string, any> | null;
  energyDistributionCompany: number;
  icmsType: string;
  injectedEnergyOffPeak?: Record<string, any>;
  injectedEnergyPeak?: Record<string, any>;
  migrationDate: string;
  nickname: string;
  powerGeneratorCost: number;
  solarPlantInOperation: boolean;
  targetVolumeGenerated: number;
  tariff: number;
}

export interface IProductOneProposalGroupEconomyData {
  simulationResultYearsList: Array<string>;
  totalCurrentSpent: number;
  totalCurrentSpentByYearList: Array<number>;
  totalSavingByYearPercentageList: Array<number>;
  totalTargetSpent: number;
  totalTargetSpentByYearList: Array<number>;
}

export interface IProductOneSimulationUnitExtendedResult extends IProductOneUnitReport, ISimulationExtendedResultBase {}
