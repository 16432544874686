import React from 'react';
import { twMerge } from 'tailwind-merge';
import imageIcon from '@assets/newProposal/raios-icon.svg';
import logoClarke from '@assets/newProposal/Logo_Clarke Energia.svg';
import LegendContainer from '@components/atoms/new-proposal/legend-container';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import { StepOfProposal } from '@components/molecules/new-proposal/schedule/steps-of-proposal';
import './styles.css';

interface Step {
  title: string;
  className: string;
}

export enum ProductType {
  Management = 'management',
  Retail = 'retail',
}

export interface ProductSteps {
  [ProductType.Management]: Step[];
  [ProductType.Retail]: Step[];
}

interface ScheduleSectionProps {
  productType: ProductType;
}

function ScheduleSection({ productType }: ScheduleSectionProps) {
  const steps: ProductSteps = {
    [ProductType.Management]: [
      { title: 'Sobre a Clarke', className: 'flex gap-5 sm:w-48' },
      { title: 'Análise de viabilidade', className: 'flex gap-5 sm:w-56' },
      { title: 'Proposta de gestão', className: 'flex gap-5 sm:w-56' },
      { title: 'Próximos passos', className: 'flex gap-5 sm:w-52' },
    ],
    [ProductType.Retail]: [
      { title: 'Sobre o Clarke Varejo', className: 'flex gap-5 sm:w-48 xl:w-48 ' },
      { title: 'Projeção de Economia', className: 'flex gap-5 sm:w-56' },
      { title: 'Resumo da proposta', className: 'flex gap-5 sm:w-80  xl:w-52' },
    ],
  };
  const stepsForProduct = steps[productType] || [];
  const lastStepIndex = stepsForProduct.length - 1;

  const managementStyles = {
    container: 'w-full h-full flex justify-center items-center xl:items-end',
    steps: 'steps-container xl:pb-20',
  };
  const retailStyles = {
    container: 'w-full h-full flex items-center xl:items-end justify-center',
    steps: 'flex flex-col gap-10 xl:flex-row justify-center xl:pb-20',
  };

  return (
    <SlideContainer className="min-h-fit h-screen">
      <section className=" sm:px-10 section-style">
        <LegendContainer legend="Nossa agenda" isScheduleSection={true} />
        <div className={twMerge(productType === 'management' ? managementStyles.container : retailStyles.container)}>
          <div className={twMerge(productType === 'management' ? managementStyles.steps : retailStyles.steps)}>
            {stepsForProduct.map((step, index) => (
              <React.Fragment key={step.title}>
                <StepOfProposal
                  imageIcon={productType === 'management' ? imageIcon : logoClarke}
                  title={step.title}
                  className={step.className}
                  productType={productType === 'retail' ? 'retail' : ''}
                />
                {index !== lastStepIndex && <hr className="line-separator" />}
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>
    </SlideContainer>
  );
}

export default ScheduleSection;
