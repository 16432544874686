export const DynamicSvgIconColor = ({
  iconName,
  colors,
  width,
  height,
  className,
  fill = 'none',
}: {
  iconName: 'wind' | 'ligthBulb' | 'dolar';
  colors: string;
  width: number;
  height: number;
  className?: string;
  fill?: string;
}) => {
  const icons = {
    wind: (
      <div style={{ background: fill }} className="flex justify-center items-center size-7 rounded-lg">
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.83353 8.40405L7.87503 1H9.95753L11.103 8.40405M8.9741 12.0995L2.04125 14.8996L1 13.0961L6.83937 8.40204M11.1087 8.40423L17 13.0082L15.9587 14.8117L8.97393 12.1017M11.3124 9.66298C11.3124 10.9544 10.2655 12.0013 8.97405 12.0013C7.68263 12.0013 6.63572 10.9544 6.63572 9.66298C6.63572 8.37156 7.68263 7.32465 8.97405 7.32465C10.2655 7.32465 11.3124 8.37156 11.3124 9.66298Z"
            stroke={colors}
            strokeWidth="1.4977"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    ligthBulb: (
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="32" height="32" rx="10" fill={fill} />
        <g clipPath="url(#clip0_1338_35)">
          <path
            d="M16 20.5V17M16 17C16.3453 17 16.6804 16.9563 17 16.874M16 17C15.6547 17 15.3196 16.9563 15 16.874M17.5 21.8596C17.0141 21.9518 16.5127 22 16 22C15.4873 22 14.9859 21.9518 14.5 21.8596M17 23.448C16.6714 23.4824 16.3377 23.5 16 23.5C15.6623 23.5 15.3286 23.4824 15 23.448M17.5 20.5V20.3722C17.5 19.7169 17.9388 19.1567 18.5056 18.8278C19.997 17.9625 21 16.3484 21 14.5C21 11.7386 18.7614 9.5 16 9.5C13.2386 9.5 11 11.7386 11 14.5C11 16.3484 12.003 17.9625 13.4944 18.8278C14.0612 19.1567 14.5 19.7169 14.5 20.3722V20.5"
            stroke={colors}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1338_35">
            <rect width="16" height="16" fill="white" transform="translate(8 8.5)" />
          </clipPath>
        </defs>
      </svg>
    ),
    dolar: (
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="32" height="32" rx="10" fill={fill} />
        <path
          d="M16 12.5V20.5M14 18.6212L14.5859 19.0607C15.3669 19.6464 16.6332 19.6464 17.4142 19.0607C18.1953 18.4749 18.1953 17.5251 17.4142 16.9393C17.0237 16.6464 16.5118 16.5 15.9999 16.5C15.5166 16.5 15.0335 16.3536 14.6647 16.0607C13.9273 15.4749 13.9273 14.5251 14.6647 13.9394C15.4021 13.3536 16.5977 13.3536 17.3351 13.9394L17.6117 14.1591M22 16.5C22 19.8137 19.3137 22.5 16 22.5C12.6863 22.5 10 19.8137 10 16.5C10 13.1863 12.6863 10.5 16 10.5C19.3137 10.5 22 13.1863 22 16.5Z"
          stroke={colors}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  };

  return (
    <div
      className={`icon-container ${className}`}
      style={{
        backgroundColor: iconName === 'ligthBulb' ? '#FFF6E0' : iconName === 'wind' ? '#E6F9DC' : '#FFF6E0',
        width: width,
        height: height,
        borderRadius: '10px 0px 0px 0px',
        opacity: 0.7,
      }}
    >
      {icons[iconName]}
    </div>
  );
};
