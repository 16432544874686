import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { GenericFormField } from './generic-form-field';

export interface ColorPickerFormFieldProps<T extends FieldValues> {
  field: Path<T>;
  id: string;
  label: string;
  placeholder?: string;
  options?: RegisterOptions<T>;
  disabled?: boolean;
  className?: string;
  tooltipContent?: string;
}

export function ColorPickerFormField<T extends FieldValues>({
  field,
  id,
  label,
  placeholder,
  options,
  disabled,
  className,
  tooltipContent,
}: ColorPickerFormFieldProps<T>) {
  const { control } = useFormContext<T>();
  const hexColorRegex = /^#([0-9A-F]{6}|[0-9A-F]{8})$/i;

  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { onChange, onBlur, value } }) => (
        <div className={`flex flex-col ${className}`}>
          <div className={`flex relative `}>
            <div
              className={twMerge(
                'w-7 h-7 flex justify-end items-end pb-2 bg bg-opacity-30',
                'absolute top-6 right-4 z-10 pt-2',
              )}
            >
              <input
                name={field}
                type="color"
                value={hexColorRegex.test(value) ? value : '#000000'}
                className="h-7 max-h-full w-7 bg-transparent border-none outline-none ring-0 rounded-full"
                onBlur={onBlur}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="xl:min-h-13">
            <GenericFormField<T>
              id={id}
              label={label}
              field={field}
              placeholder={placeholder || 'Digite aqui'}
              options={{
                required: options?.required === true ? { value: true, message: 'Campo é obrigatório' } : false,
                validate: (value) =>
                  options?.required
                    ? value && hexColorRegex.test(value)
                      ? true
                      : 'O valor deve estar no formato #rrggbb ou #rrggbbaa'
                    : true,
              }}
              className="w-full"
              disabled={disabled}
              tooltipContent={tooltipContent}
            />
          </div>
        </div>
      )}
    />
  );
}
