import {
  parseAclPricesItem,
  parseCoverCceeTaxesItem,
  parseGuaranteeTypeItem,
  parseManagementTerminationFineItem,
  parseManagerTypeItem,
  parseMonthlyManagementPricePerUnitItem,
  parseProductTypeItem,
  parseSavingItem,
  parseUpperAndLowerFlexibilityItem,
} from './helper';

export interface ICompetitiveAnalysisData {
  productType: ICompetitveAnalysisItem;
  managerType: ICompetitveAnalysisItem;
  upperFlexibility: ICompetitveAnalysisItem;
  lowerFlexibility: ICompetitveAnalysisItem;
  guaranteeType: ICompetitveAnalysisItem;
  chargesCoverageType: ICompetitveAnalysisItem;
  managementTerminationFine: ICompetitveAnalysisItem;
  monthlyManagementPricePerUnit: ICompetitveAnalysisItem;
  totalMonthlyManagementCost: ICompetitveAnalysisItem;
  saving: ICompetitveAnalysisItem;
  savingByMonth: ICompetitveAnalysisItem;
  aclPrices: Record<string, number>;
}

export type ICompetitveAnalysisItemAssessment = 'POSITIVE' | 'NEGATIVE' | 'REGULAR' | undefined;
export interface ICompetitveAnalysisItem {
  value: string | boolean | number | JSX.Element;
  assessment?: ICompetitveAnalysisItemAssessment;
}

interface ICompetitveAnalysisTableColumns {
  item: string;
  firstCompetitor: JSX.Element;
  secondCompetitor: JSX.Element;
  thirdCompetitor: JSX.Element | null;
}

enum CompetitiveAnalysisFactorEnum {
  MANAGER_TYPE = 'Tipo de empresa',
  PRODUCT_TYPE = 'Modalidade',
  UPPER_FLEXIBILITY = 'Flexibilidade',
  LOWER_FLEXIBILITY = 'Flexibilidade',
  GUARANTEE_PERIOD = 'Garantia',
  COVER_CCEE_TAXES = 'Encargos',
  POS_MIGRATION_MANAGEMENT_PRICE = 'Preço de gestão / mês (pós-migração)',
  MANAGEMENT_TERMINATION_FINE = 'Multa de rescisão de gestão',
  SAVING = 'Economia',
  ACL_PRICES = 'Preço de energia (R$/MWh)',
}

export const parseCompetitiveAnalysisData = (
  competitiveAnalysis: ICompetitiveAnalysisData[],
): ICompetitveAnalysisTableColumns[] => {
  const firstCompetitor = competitiveAnalysis[0];
  const secondCompetitor = competitiveAnalysis[1];
  const thirdCompetitor = competitiveAnalysis.length >= 3 ? competitiveAnalysis[2] : null;

  const competitiveAnalysisFactorsIndexer = [
    {
      title: CompetitiveAnalysisFactorEnum.MANAGER_TYPE,
      parser: parseManagerTypeItem,
    },
    {
      title: CompetitiveAnalysisFactorEnum.PRODUCT_TYPE,
      parser: parseProductTypeItem,
    },
    {
      title: CompetitiveAnalysisFactorEnum.UPPER_FLEXIBILITY,
      parser: parseUpperAndLowerFlexibilityItem,
    },
    {
      title: CompetitiveAnalysisFactorEnum.GUARANTEE_PERIOD,
      parser: parseGuaranteeTypeItem,
    },
    {
      title: CompetitiveAnalysisFactorEnum.COVER_CCEE_TAXES,
      parser: parseCoverCceeTaxesItem,
    },
    {
      title: CompetitiveAnalysisFactorEnum.POS_MIGRATION_MANAGEMENT_PRICE,
      parser: parseMonthlyManagementPricePerUnitItem,
    },
    {
      title: CompetitiveAnalysisFactorEnum.MANAGEMENT_TERMINATION_FINE,
      parser: parseManagementTerminationFineItem,
    },
    {
      title: CompetitiveAnalysisFactorEnum.SAVING,
      parser: parseSavingItem,
    },
    {
      title: CompetitiveAnalysisFactorEnum.ACL_PRICES,
      parser: parseAclPricesItem,
    },
  ];

  return competitiveAnalysisFactorsIndexer.map(({ title, parser }) => ({
    item: title,
    firstCompetitor: parser(firstCompetitor),
    secondCompetitor: parser(secondCompetitor),
    thirdCompetitor: thirdCompetitor && parser(thirdCompetitor),
  }));
};
