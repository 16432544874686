import { twMerge } from 'tailwind-merge';
import useWhiteLabel from '@hooks/use-white-label';
import { DynamicSvgColorPicker } from './dynamic-svg-color-picker';
import './styles.css';

interface ISectionProposalContainerProps {
  children: JSX.Element;
  image?: string;
  className?: string;
  imgStyles?: string;
  darkTheme?: boolean;
  imagePath?: string;
  isCoverMain?: boolean;
}

export function SectionProposalHeroContainer({
  isCoverMain,
  children,
  image,
  imagePath,
  className,
  imgStyles,
  darkTheme,
}: ISectionProposalContainerProps): JSX.Element {
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();
  return (
    <section className={twMerge('section-hero-container', className)}>
      {children}
      <div
        id="background-container"
        className={twMerge(
          ' min-h-full',
          !image && 'sm:w-full sm:flex-col-row ',
          darkTheme === true ? 'bg-15/14' : 'bg-10/11',
          !isWhiteLabelProposal && (imagePath ? `bg-[url(${image})]` : 'bg-[url(assets/newProposal/raios-desk.svg)]'),
        )}
      >
        {image && !isWhiteLabelProposal && (
          <img className={twMerge(imgStyles)} src={image} alt="Imagem de um Computador com gráficos sendo exibidos" />
        )}
        {isWhiteLabelProposal && whiteLabelSupplier?.colors && (
          <DynamicSvgColorPicker
            className={twMerge(
              'absolute',
              isCoverMain
                ? ' h-full w-11/12 -top-5 right-5 xl:min-w-96 xl:top-5 xl:-right-60 desktop:-right-80 2xl:-right-100 '
                : ' h-5/6 w-10/12 -top-6 right-8 xl:min-w-[60rem] xl:min-h-full  xl:botton-0 xl:-right-40 2xl:-right-72',
            )}
            colors={whiteLabelSupplier?.colors}
            type={isCoverMain ? 'main' : 'secondary'}
          />
        )}
      </div>
    </section>
  );
}
