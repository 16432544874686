import React from 'react';

const ErrorUpdateTheProposal: React.FC = () => {
  return (
    <div className="flex overflow-y-auto flex-col justify-center items-center h-screen align-middle md:flex-row md:px-36">
      <div className="flex flex-col justify-start items-start pl-10 mx-20 text-left align-middle border-l-2 border-clarke-green">
        <div className="font-semibold text-heading-xlarge">Ops! Atualize a proposta...</div>
        <div className="mt-2 w-full text-base font-light text-neutral-60">
          Esta proposta foi gerada com a versão anterior do sistema. Para acessar essa página, por favor, refaça a
          simulação.
        </div>
      </div>
    </div>
  );
};

export default ErrorUpdateTheProposal;
