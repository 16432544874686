import { twMerge } from 'tailwind-merge';
import useProductOne from '@hooks/use-product-one';
import useProductTwo from '@hooks/use-product-two';
import { IProductOneEnergyTypeComparison } from '@contexts/product-one/types';
import LegendContainer from '@components/atoms/new-proposal/legend-container';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import { EnergyTypeComparasionCard } from '@components/molecules/new-proposal/energy-comparasion';
import './style.css';

export function EnergyTypeComparasionSection() {
  const { proposal: productOneProposal } = useProductOne();
  const { proposal: productTwoProposal } = useProductTwo();

  const isRetailPath = window.location.pathname.includes('clarke-varejo');

  const energyTypeComparison = isRetailPath
    ? productTwoProposal?.calculatorMetadata?.bestRetailTraderResult?.calculatorMetadata?.energyTypeComparison
    : productOneProposal?.calculatorMetadata?.firstResult?.energyTypeComparison ??
      ({} as IProductOneEnergyTypeComparison);
  const isComparingIncentivized100 = !!energyTypeComparison?.incentivized100;
  const isComparingIncentivized50 = !!energyTypeComparison?.incentivized50;
  const isVisible = isComparingIncentivized100 && isComparingIncentivized50;

  const isPrintMode = window.location.href.includes('print');
  return (
    <SlideContainer className="p-6 xl:p-0 xl:pt-10" isVisible={isVisible}>
      <>
        {isVisible && (
          <>
            <LegendContainer
              className={twMerge(isPrintMode ? 'xl:pt-36' : 'xl:pt-20 ', 'xl:pr-20 2xl:p-40')}
              legend="Comparativo de energia"
            />
            <section className="flex flex-col xl:pl-20  gap-4 xl:relative min-h-fit">
              <h1 className="text-brand-primary-60 text-heading-xsmall xl:text-heading-xlarge font-bold w-68 xl:w-full">
                Comparativo de melhor tipo de energia
              </h1>
              <div className="flex flex-col xl:flex-row justify-start bg-image 2xl:max-w-full gap-6">
                <div className="bg-white flex flex-col xl:flex-row gap-6 xl:gap-10 xl:min-h-[550px] 2xl:min-h-[700px] pt-10 desktop:max-w-screen-xl">
                  <EnergyTypeComparasionCard energyType="i50" {...energyTypeComparison?.incentivized50} />
                  {energyTypeComparison?.incentivized100 && (
                    <EnergyTypeComparasionCard energyType="i100" {...energyTypeComparison?.incentivized100} />
                  )}
                </div>
              </div>
            </section>
          </>
        )}
      </>
    </SlideContainer>
  );
}
