import { formatNumber } from '@utils/numeral';

export const EnergyComparisonEconomy = ({
  title,
  totalSaving,
  totalSavingPercentage,
}: {
  title: string;
  totalSaving?: number;
  totalSavingPercentage?: number;
}) => (
  <div data-cy={`${title.replace(' ', '-')}-economy`}>
    <h2 className="energy-comparison-economy-title">Economia</h2>
    <span className="flex gap-2">
      <p className="energy-comparison-economy-value">{formatNumber(totalSaving, '$ 0,0.00')}</p>
      <p className="energy-comparison-percentage">{formatNumber(totalSavingPercentage, '%')}</p>
    </span>
  </div>
);
