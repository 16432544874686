import React from 'react';

import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { createContext } from 'use-context-selector';

import { useAuth } from '@src/auth-wrapper';

import config from '@config';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { ICommercialDealerComission, IResponseBase, IUpdateSellerPayload } from '@contexts/types';
import { IProductOneProposal, IProductOneSimulatorInputs } from '@contexts/product-one/types';

import { getCalculatorName, transformObjectKeysToSnakeCase } from '@utils/common';

import { parseProductOneProposal, parseProductOneSimulationPayload, parseProductOneSimulatorResult } from './parser';
import { isDateAfterTarget } from '@contexts/helper';
import { IProductOneContext } from './types';

export const ProductOneContext = createContext({} as IProductOneContext);

interface Provider {
  children: React.ReactNode;
}

const ProductOneProvider: React.FC<Provider> = ({ children }) => {
  const { user } = useAuth();

  const productOneCalculatorBaseURL = `${config.CALCULATOR_API_HOST}/api/v3/product-one`;
  const calculatorApiClient = axios.create({
    baseURL: productOneCalculatorBaseURL,
    headers: {
      Authorization: `Apikey ${config.CALCULATOR_API_KEY}`,
    },
  });
  const [proposal, setProposal] = React.useState<IProductOneProposal>();
  const [isLoadingProductOne, setIsLoadingProductOne] = React.useState<boolean>(false);
  const [isValidProposalProductOne, setIisValidProposalProductOne] = React.useState<boolean>(false);

  function createProductOneSimulation(simulationData: IProductOneSimulatorInputs): AxiosPromise {
    const simulationPayload = parseProductOneSimulationPayload(simulationData);
    return calculatorApiClient.post('/simulation', simulationPayload);
  }

  function createProductOneProposal(simulatorResult: AxiosResponse<IResponseBase<any>>): AxiosPromise {
    const parsedPayload = parseProductOneSimulatorResult(simulatorResult);
    return calculatorApiClient.post('proposals', parsedPayload);
  }

  function getProductOneProposals(leadId?: string, sellerId?: string): AxiosPromise {
    return calculatorApiClient.get('proposals', {
      params: {
        lead_id: leadId,
        seller_id: sellerId,
      },
    });
  }

  async function getProductOneProposalById(proposalId: string): Promise<IProductOneProposal | null> {
    setIsLoadingProductOne(true);
    try {
      const response = await calculatorApiClient.get(`proposals/${proposalId}`);
      const parsedProposal = parseProductOneProposal(response.data.data);
      setProposal(parsedProposal);

      const isProposalAfterTargetDate = isDateAfterTarget(parsedProposal.createdAt);
      setIisValidProposalProductOne(isProposalAfterTargetDate);

      eventTracker.trackEvent(eventLabels.SIMULATION_PAGE_VIEWED, {
        product: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
      return parsedProposal;
    } catch {
      return null;
    } finally {
      setIsLoadingProductOne(false);
    }
  }

  function deleteProductOneProposalsHandler(leadId: string, proposalsIds: Array<string>): AxiosPromise {
    return calculatorApiClient.post(`/proposals/delete`, { lead_id: leadId, proposals_ids: proposalsIds });
  }

  function deleteProductOneAllProposalsByLeadIdHandler(leadId: string): AxiosPromise {
    return calculatorApiClient.post(`/proposals/delete`, { lead_id: leadId }, { params: { all_from_lead: true } });
  }

  function updateSellerOfProductOneProposal(updateSellerData: IUpdateSellerPayload): AxiosPromise {
    return calculatorApiClient.post(`/proposals/update-seller/${updateSellerData.proposalId}`, {
      seller: {
        id: updateSellerData.seller.id,
        name: updateSellerData.seller.name,
        picture_url: updateSellerData.seller.pictureUrl,
      },
      lead_id: updateSellerData.leadId,
    });
  }

  function updateProductOneProposalCommercialDealerComission(
    proposalId: string,
    commercialDealerComissionData: ICommercialDealerComission,
  ): AxiosPromise {
    const commercialDealerComissionPayload = transformObjectKeysToSnakeCase(commercialDealerComissionData);
    return calculatorApiClient.post(`/update-proposal-comission/${proposalId}`, commercialDealerComissionPayload);
  }

  return (
    // @ts-expect-error -> generic error caused by external lib

    <ProductOneContext.Provider
      value={{
        proposal,
        isLoadingProductOne,
        isValidProposalProductOne,
        createProductOneSimulation,
        createProductOneProposal,
        getProductOneProposalById,
        getProductOneProposals,
        deleteProductOneProposalsHandler,
        deleteProductOneAllProposalsByLeadIdHandler,
        updateSellerOfProductOneProposal,
        updateProductOneProposalCommercialDealerComission,
      }}
    >
      {children}
    </ProductOneContext.Provider>
  );
};

export default ProductOneProvider;
