import treeIconSvg from '@assets/newProposal/TreeSvg.svg';
import leafIconSvg from '@assets/newProposal/LeafSvg.svg';
import { IEnergyTypeComparisonItem } from '@contexts/product-two/types';
import { EnergyComparisonHeader } from '@components/atoms/new-proposal/energy-comparasion/header';
import { EnergyComparisonEconomy } from '@components/atoms/new-proposal/energy-comparasion/economy';
import { EnergyComparisonBody } from '@components/atoms/new-proposal/energy-comparasion/body';

interface IEnergyTypeComparasionCard extends IEnergyTypeComparisonItem {
  energyType: 'i50' | 'i100';
}

export function EnergyTypeComparasionCard({
  energyType,
  totalSaving,
  totalSavingPercentage,
  rankingPosition,
}: IEnergyTypeComparasionCard) {
  const isI50 = energyType === 'i50';
  const title = `Energia incentivada ${isI50 ? '50%' : '100%'}`;
  const colors = isI50 ? '#00bf6a' : '#e0a000';
  const fill = isI50 ? '#E6F9DC' : '#FFF6E0';
  const icon = isI50 ? leafIconSvg : treeIconSvg;

  return (
    <div className={`energy-comparison-card energy-comparison-${energyType}`}>
      <EnergyComparisonHeader icon={icon} title={title} rankingPosition={rankingPosition} />
      <EnergyComparisonEconomy title={title} totalSaving={totalSaving} totalSavingPercentage={totalSavingPercentage} />
      <EnergyComparisonBody energyType={energyType} fill={fill} colors={colors} />
    </div>
  );
}
