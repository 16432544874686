import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';

import config from '@config';

import { transformObjectKeysToSnakeCase } from '@utils/common';

import { ICommercialDealerComission, IResponseBase, IUpdateSellerPayload } from '@contexts/types';

import { isDateAfterTarget } from '@contexts/helper';
import { IProductTwoSimulatorInputs, IProductTwoContext, IProductTwoProposal } from './types';
import { parseProductTwoProposal, parseProductTwoSimulationPayload } from './parser';

export const ProductTwoContext = createContext({} as IProductTwoContext);

interface Provider {
  children: React.ReactNode;
}

const ProductTwoProvider: React.FC<Provider> = ({ children }) => {
  const productTwoCalculatorBaseURL = `${config.CALCULATOR_API_HOST}/api/v1/product-two`;
  const calculatorApiClient = axios.create({
    baseURL: productTwoCalculatorBaseURL,
    headers: {
      Authorization: `Apikey ${config.CALCULATOR_API_KEY}`,
    },
  });

  const [proposal, setProposal] = React.useState<IProductTwoProposal>({} as IProductTwoProposal);
  const [isLoadingProductTwo, setIsLoadingProductTwo] = React.useState<boolean>(false);
  const [isValidProposalProductTwo, setIisValidProposalProductTwo] = React.useState<boolean>(false);

  async function createProductTwoSimulation(
    simulationData: IProductTwoSimulatorInputs,
  ): AxiosPromise<IResponseBase<IProductTwoProposal>> {
    const simulationPayload = parseProductTwoSimulationPayload(simulationData);
    return calculatorApiClient.post('/simulation', simulationPayload);
  }

  function getProductTwoProposals(
    leadId?: string,
    sellerId?: string,
  ): AxiosPromise<IResponseBase<Array<IProductTwoProposal>>> {
    return calculatorApiClient.get('proposals', {
      params: {
        lead_id: leadId,
        seller_id: sellerId,
      },
    });
  }

  function getProductTwoProposalById(proposalId?: string): AxiosPromise<IResponseBase<IProductTwoProposal>> {
    calculatorApiClient
      .get(`proposals/${proposalId}`)
      .then((response) => {
        const parsedProposal: IProductTwoProposal = parseProductTwoProposal(response.data.data);
        setProposal(parsedProposal);
        const isProposalAfterTargetDate = isDateAfterTarget(parsedProposal?.createdAt);
        setIsLoadingProductTwo(true);
        setIisValidProposalProductTwo(isProposalAfterTargetDate);
      })
      .catch(() => {
        setIsLoadingProductTwo(false);
      })
      .finally(() => {
        setIsLoadingProductTwo(false);
      });
    return calculatorApiClient.get(`proposals/${proposalId}`);
  }

  function deleteProductTwoProposalsHandler(leadId: string, proposalsIds: Array<string>): AxiosPromise<IResponseBase> {
    return calculatorApiClient.post(`/proposals/delete`, { lead_id: leadId, proposals_ids: proposalsIds });
  }

  function deleteProductTwoAllProposalsByLeadIdHandler(leadId: string): AxiosPromise<IResponseBase> {
    return calculatorApiClient.post(`/proposals/delete`, { lead_id: leadId }, { params: { all_from_lead: true } });
  }
  function updateSellerOfProductTwoProposal(updateSellerData: IUpdateSellerPayload): AxiosPromise<IResponseBase> {
    return calculatorApiClient.post(`/proposals/update-seller/${updateSellerData.proposalId}`, {
      seller: {
        id: updateSellerData.seller.id,
        name: updateSellerData.seller.name,
        picture_url: updateSellerData.seller.pictureUrl,
      },
      lead_id: updateSellerData.leadId,
    });
  }

  function updateProductTwoProposalCommercialDealerComission(
    proposalId: string,
    commercialDealerComissionData: ICommercialDealerComission,
  ): AxiosPromise {
    const commercialDealerComissionPayload = transformObjectKeysToSnakeCase(commercialDealerComissionData);
    return calculatorApiClient.post(`/update-proposal-comission/${proposalId}`, commercialDealerComissionPayload);
  }

  return (
    //@ts-expect-error
    <ProductTwoContext.Provider
      value={{
        proposal,
        isLoadingProductTwo,
        isValidProposalProductTwo,
        createProductTwoSimulation,
        getProductTwoProposalById,
        getProductTwoProposals,
        deleteProductTwoProposalsHandler,
        deleteProductTwoAllProposalsByLeadIdHandler,
        updateSellerOfProductTwoProposal,
        updateProductTwoProposalCommercialDealerComission,
      }}
    >
      {children}
    </ProductTwoContext.Provider>
  );
};

export default ProductTwoProvider;
