import { twMerge } from 'tailwind-merge';
import { Tooltip } from '@clarke-energia/foton';

interface EnergyComparisonTableProps {
  data: {
    incentivized50: { label: string; value: string; tooltipContent: string }[];
    incentivized100: { label: string; value: string; tooltipContent: string }[];
  };
}

export function EnergyComparisonTable({ data }: EnergyComparisonTableProps) {
  const headers = ['Descrição', 'Incentivado 50%', 'Incentivado 100%'];

  const rows = data.incentivized50.map((item, index) => ({
    label: item.label,
    incentivized50: item.value,
    incentivized100: data.incentivized100[index]?.value || '',
    tooltipContent: item.tooltipContent,
  }));

  return (
    <table className="min-w-full border border-gray-200">
      <thead>
        <tr className="bg-gray-100">
          {headers.map((header, i) => (
            <th key={i} className="py-2 px-4 border border-gray-300 text-left">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr key={i} className={twMerge('hover:bg-primary-10', i % 2 === 0 ? 'bg-white' : 'bg-gray-50')}>
            <td className="py-2 px-4 border border-gray-300 text-left">
              <span className="flex items-center gap-10 justify-between pr-6">
                {row.label}
                {row.tooltipContent && <Tooltip content={row.tooltipContent} />}
              </span>
            </td>
            <td className="py-2 px-4 border border-gray-300 text-left">{row.incentivized50}</td>
            <td className="py-2 px-4 border border-gray-300 text-left">{row.incentivized100}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
