import { twMerge } from 'tailwind-merge';
import CardProposalContainer from './container';

interface IAnnualDetailCard {
  year: string;
  supply: string | number;
  economy: string | number;
  captiveMarket: string | number;
  freeMarket: string | number;
  className?: string;
  spanStyle?: string;
}

function AnnualDetailCard({
  year,
  supply,
  economy,
  captiveMarket,
  freeMarket,
  className,
  spanStyle,
}: IAnnualDetailCard) {
  return (
    <div
      className={twMerge(
        'grid grid-cols-1 gap-x-5 gap-y-2 items-start justify-around px-2 min-w-[20rem] xl:min-w-fit min-h-fit',
        className,
      )}
    >
      <span className={twMerge('font-bold xl:text-paragraph-large 2xl:text-heading-small', spanStyle)}>{year}</span>
      <CardProposalContainer
        supply={supply as number}
        economy={economy as number}
        captiveMarket={captiveMarket as number}
        freeMarket={freeMarket as number}
      />
    </div>
  );
}

export default AnnualDetailCard;
