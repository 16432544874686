import { twMerge } from 'tailwind-merge';
import { DynamicSvgIconColor } from './dynamic-icon-color';

export const EnergyComparisonItem = ({
  fill,
  colors,
  iconName,
  description,
}: {
  fill: string;
  colors: string;
  iconName: 'wind' | 'dolar' | 'ligthBulb';
  description: string;
}) => (
  <span className="energy-comparison-item">
    <div className={twMerge('flex size-7')}>
      <DynamicSvgIconColor fill={fill} width={16} height={16} iconName={iconName} colors={colors} />
    </div>
    <p className="energy-comparison-icon-description">{description}</p>
  </span>
);
