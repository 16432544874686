import DesktopImage from '@assets/newProposal/desktop.png';
import { SectionProposalHeroContainer } from '@components/atoms/new-proposal/hero-container/hero';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';

function NextStepsProposal() {
  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  return (
    <SlideContainer>
      <SectionProposalHeroContainer
        className="min-h-fit h-screen xl:min-h-100"
        image={DesktopImage}
        imgStyles={'mt-100 md:mt-40 xl:mt-0 w-full 2xl:w-10/12 xl:mx-auto p-10 xl:-mt-32'}
      >
        <div className="w-full xl:w-fit 2xl:w-2/5 flex items-end justify-center pb-8 xl:pb-24">
          {isManagementPath ? (
            <h1 className="w-10/12 xl:w-2/5 text-display-small xl:text-display-large 2xl:text-display-xlarge font-extrabold font-poppins text-primary-70">
              Próximos passos
            </h1>
          ) : (
            <p className="w-10/12 xl:w-2/5 text-display-small xl:text-display-large 2xl:text-display-xlarge font-extrabold font-poppins text-primary-70">
              <b>Resumo</b> <b className="whitespace-nowrap">da Proposta</b>
            </p>
          )}
        </div>
      </SectionProposalHeroContainer>
    </SlideContainer>
  );
}

export default NextStepsProposal;
