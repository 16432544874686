import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

interface IStepOfProposalProps {
  imageIcon: string;
  title: string;
  className?: string;
  productType?: string;
}

export function StepOfProposal({ imageIcon, className, title, productType }: IStepOfProposalProps) {
  return (
    <div className={twMerge('flex xl:flex-col xl:items-center xl:justify-end xl:h-28', className)}>
      <img src={imageIcon} alt="Icon" />
      <div
        className={twMerge(
          productType && 'w-64',
          'xl:text-center text-heading-medium font-bold xl:text-heading-large 2xl:text-heading-2xlarge xl:font-extrabold',
        )}
      >
        {title}
      </div>
    </div>
  );
}

StepOfProposal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};
